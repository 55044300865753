import ACT from "../assets/drivers/desktop/ACT.png";
import NSW from "../assets/drivers/desktop/NSW.png";
import SA from "../assets/drivers/desktop/SA.png";
import TAS from "../assets/drivers/desktop/TAS.png";
import NT from "../assets/drivers/desktop/NT.png";
import WA from "../assets/drivers/desktop/WA.png";
import QLD from "../assets/drivers/desktop/QLD.png";
import VIC from "../assets/drivers/desktop/VIC.png";
import ACT_mobile from "../assets/drivers/mobile/ACT.png";
import NSW_mobile from "../assets/drivers/mobile/NSW.png";
import SA_mobile from "../assets/drivers/mobile/SA.png";
import TAS_mobile from "../assets/drivers/mobile/TAS.png";
import NT_mobile from "../assets/drivers/mobile/NT.png";
import WA_mobile from "../assets/drivers/mobile/WA.png";
import QLD_mobile from "../assets/drivers/mobile/QLD.png";
import VIC_mobile from "../assets/drivers/mobile/VIC.png";
import {IIndividualModel} from "../../models/IndividualModel";
import TextFieldWrapper from "./TextFieldWrapper";
import React from "react";
import {observer} from "mobx-react-lite";
import {css} from "@emotion/css";
import {FieldTooltipProps} from "../common/FieldTooltip";

function licenceCardNumberToolTip(state?: string) : Omit<FieldTooltipProps, "children"> {
    const className = css`max-height: 490px; max-width: calc(100vw - 8px)`;

    switch (state) {
        case "ACT":
            return {
                toolTipContent:<img src={ACT} alt = "ACT Licence" className = {className} />,
                toolTipMobileContent:<img src={ACT_mobile} alt = "ACT Licence" className = {className} />,
                modalOnMobile: true
            };
        case "NSW":
            return {
                toolTipContent:<img src={NSW} alt = "NSW Licence" className = {className} />,
                toolTipMobileContent:<img src={NSW_mobile} alt = "NSW Licence" className = {className} />,
                modalOnMobile: true
            };
        case "SA":
            return {
                toolTipContent:<img src={SA} alt = "SA Licence" className = {className} />,
                toolTipMobileContent:<img src={SA_mobile} alt = "SA Licence" className = {className} />,
                modalOnMobile: true
            };
        case "TAS":
            return {
                toolTipContent:<img src={TAS} alt = "TAS Licence" className = {className} />,
                toolTipMobileContent:<img src={TAS_mobile} alt = "TAS Licence" className = {className} />,
                modalOnMobile: true
            };
        case "VIC":
            return {
                toolTipContent:<img src={VIC} alt = "VIC Licence" className = {className} />,
                toolTipMobileContent:<img src={VIC_mobile} alt = "VIC Licence" className = {className} />,
                modalOnMobile: true
            };
        case "NT":
            return {
                toolTipContent:<img src={NT} alt = "NT Licence" className = {className} />,
                toolTipMobileContent:<img src={NT_mobile} alt = "NT Licence" className = {className} />,
                modalOnMobile: true
            };
        case "WA":
            return {
                toolTipContent:<img src={WA} alt = "WA Licence" className = {className} />,
                toolTipMobileContent:<img src={WA_mobile} alt = "WA Licence" className = {className} />,
                modalOnMobile: true
            };
        case "QLD":
            return {
                toolTipContent:<img src={QLD} alt = "QLD Licence" className = {className} />,
                toolTipMobileContent: <img src={QLD_mobile} alt = "QLD Licence" className = {className} />,
                modalOnMobile: true
            };
        default:
            return {};
    }
}


function placeholderText(state: string) {
    return state === "VIC" ? "Optional" : "";
}

const DriversLicenceNumberEditor = (props: {individual: IIndividualModel; gridOptions: any}) => {
    return props.individual.showIdStateOrDriversCardNumber() && (
        <TextFieldWrapper
            fieldModel={props.individual.driversCardNumber}
            gridOptions={props.gridOptions}
            placeholder={placeholderText(props.individual.idState.value)}
            tooltip={licenceCardNumberToolTip(props.individual.idState.value)}
        />
    )
};

export default observer(DriversLicenceNumberEditor);